<template>
  <v-list-item
      link
      :title="$t('menus.admin')"
      href="/plannet/module/administration/"
      target="_blank"
      color="white"
  >
    <v-list-item-icon class="d-flex justify-center">
      <v-icon color="white">mdi-account-cog</v-icon>
    </v-list-item-icon>
    <v-list-item-content link>
      <v-list-item-title class="white--text">
        {{ $t("menus.admin") }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import Vuex from "vuex"

export default {
  name: "MenuAdmin",
  computed: {
    ...Vuex.mapState(["informations"]),
    isAdmin() {
      return this.informations
          ? this.informations.administrator || false
          : false
    },
  }
}
</script>

<style scoped>

</style>